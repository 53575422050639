import isClient from 'utils/isClient'
import { GTM_ID } from 'gatsby-env-variables'

const startGTM = () => {
  if (!GTM_ID || !isClient()) return ''

  const scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'

  const content = String.raw`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${GTM_ID}');`

  scriptTag.text = content
  document.head.appendChild(scriptTag)
}

const noScriptGTM = () => {
  if (!GTM_ID || !isClient()) return ''

  const noscriptTag = document.createElement('noscript')
  const iframeTag = document.createElement('iframe')

  iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`
  iframeTag.height = 0
  iframeTag.width = 0
  iframeTag.style = 'display:none;visibility:hidden'

  noscriptTag.appendChild(iframeTag)
  document.body.appendChild(noscriptTag)
}

const loadGTM = () => {
  startGTM()
  noScriptGTM()
}

export default loadGTM
