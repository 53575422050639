import loadGTM from './loadGTM'
import loadAsyncSVG from './loadAsyncSVG'
import loadNewRelic from './loadNewRelic'

const init = () => {
  loadGTM()
  loadAsyncSVG()
  loadNewRelic()
}

export default init
