import axios from 'axios'
import spritePath from 'images/sprite.svg'

function createSVGDefWrapper(data) {
  const wrapper = document.createElement('div')
  wrapper.id = 'icons-sprite'
  wrapper.innerHTML = data
  document.body.insertBefore(wrapper, document.body.childNodes[0])
}

function getSVGDef() {
	const onRequestLoad = response => {
    createSVGDefWrapper(response.data)
    console.log('SVGs is done!')
  }

	const axiosOptions = {
    url: spritePath,
    method: 'GET',
    headers: {
      Accept: '*/*',
    },
  }

  axios(axiosOptions)
    .then(onRequestLoad)
    .catch(e => {
      console.error(`Error on get SVG: ${e.code}`)
    })
}

export default getSVGDef
